import React from 'react';
import { connect } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { ReactComponent as PlusIcon } from '../../../../assets/img/icons/common/plus.svg';
import { withRouter } from 'react-router';
import { useComponents } from '../../../../zustand/componentStore.js';

import {
    Row,
    Button,
} from 'reactstrap';

import 'react-modern-drawer/dist/index.css';
import DraggableComponent from '../DraggableComponent.js';
import ComponentSelector from '../ComponentSelector.js';
import PricingRuleSelector from '../PricingRuleSelector.js';
import MarginProfileSelector from '../MarginProfileSelector.js';
import MarginSection from '../MarginSection.js';
import CustomFieldSection from '../CustomFieldSection.js';
import DynamicPriceSection from '../DynamicPriceSection.js';

const PackagingComponentStructure = (props) => {
    const {
        packageDetails,
        userId,
        values,
        setFieldValue,
        existingProduct,
        marginsData,
        marginProfilesData,
        dynamicPriceData,
        role,
        notificationOpen,

        componentsTypeData,
    } = props;

    const { actions, selectedComponentType } = useComponents();

    const getComponentTypeId = (name) => {
        return componentsTypeData.find((x) => x.name === name)?._id;
    };

    return (
        <>
            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Packaging*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('packagingComponents'),
                            actions.setSelectedComponentType('Packaging'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Packaging')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                <Droppable droppableId='packagingComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values?.packagingComponents?.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.packagingComponents}
                                    supportNested={true}
                                    location={'packagingComponents'}
                                    description={c.description}
                                />                                                                                                                
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Materials*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        onClick={() => {[
                            actions.setStoredLocation('materialComponents'),
                            actions.setSelectedComponentType('Material'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Material')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ];}}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='materialComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.materialComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.materialComponents}
                                    supportNested={true}
                                    location={'materialComponents'}
                                    description={c.description}
                                />                                                                                                                
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Printing Size*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        disabled={values.printSizeComponents.length > 0}
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('printSizeComponents'),
                            actions.setSelectedComponentType('Printing Size'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Printing Size')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='printSizeComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.printSizeComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.printSizeComponents}
                                    supportNested={false}
                                    location={'printSizeComponents'}
                                    description={c.description}
                                />                                                                                                       
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Printing Cost*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('printCostComponents'),
                            actions.setSelectedComponentType('Printing Cost'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Printing Cost')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>
                
                <Droppable droppableId='printCostComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.printCostComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.printCostComponents}
                                    supportNested={false}
                                    location={'printCostComponents'}
                                    description={c.description}
                                />                                                                                                  
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-light-grey py-2 px-3 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Quantity*</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        disabled={values.quantityComponents.length > 0}
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('quantityComponents'),
                            actions.setSelectedComponentType('Quantity'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('Quantity')),
                            actions.setModalAllowBack(false),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>

                <Droppable droppableId='quantityComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.quantityComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.quantityComponents}
                                    supportNested={false}
                                    location={'quantityComponents'}
                                    description={c.description}
                                />                                                                                                
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            <div className='rounded bg-grey px-3 py-2 mb-3'>
                <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                    <h4 className='m-0'>Additional Components</h4>
                    <Button
                        color='primary'
                        className='shadow-none rounded-circle p-1 m-0 d-flex'
                        style={{ height: 25, width: 25 }}
                        onClick={() => [
                            actions.setStoredLocation('additionalComponents'),
                            actions.setSelectedComponentType('Options'),
                            actions.setSelectedComponentTypeId(getComponentTypeId('')),
                            actions.setModalAllowBack(true),
                            actions.setExcludedComponentTypes(['Packaging', 'Material', 'Binding Type', 'Printing Size', 'Printing Cost', 'Quantity', 'Production Timeline', 'Artwork Service', 'File Storage']),
                            actions.setAddComponentModalIsOpen(true),
                        ]}
                    >
                        <PlusIcon
                            height={15}
                            width={15}
                        />
                    </Button>
                </Row>

                <Droppable droppableId='additionalComponents'>
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {values.additionalComponents.map((c, index) => (
                                <DraggableComponent
                                    key={`${c._id ?? c.name}-${index}`}
                                    values={values}
                                    index={index}
                                    name={c.name}
                                    componentTypeName={c.componentTypeName}
                                    code={c.code}
                                    setFieldValue={setFieldValue}
                                    component={c}
                                    selectedComponents={values.additionalComponents}
                                    supportNested={false}
                                    location={'additionalComponents'}
                                    description={c.description}
                                />                                                                                          
                            ))}
                            
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>

            {(role !== 'superadmin') && (
                <>
                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Production Timeline</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.productionComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('productionComponents'),
                                    actions.setSelectedComponentType('Production Timeline'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Production Timeline')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='productionComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.productionComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.productionComponents}
                                            supportNested={false}
                                            location={'productionComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>Artwork Service</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.artworkComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('artworkComponents'),
                                    actions.setSelectedComponentType('Artwork Service'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('Artwork Service')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='artworkComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.artworkComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.artworkComponents}
                                            supportNested={false}
                                            location={'artworkComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <div className='rounded bg-grey px-3 py-2 mb-3'>
                        <Row className='px-3 py-2 d-flex justify-content-between align-items-center'>
                            <h4 className='m-0'>File Upload</h4>
                            <Button
                                color='primary'
                                className='shadow-none rounded-circle p-1 m-0 d-flex'
                                style={{ height: 25, width: 25 }}
                                disabled={values.fileStorageComponents.length > 0}
                                onClick={() => [
                                    actions.setStoredLocation('fileStorageComponents'),
                                    actions.setSelectedComponentType('File Storage'),
                                    actions.setSelectedComponentTypeId(getComponentTypeId('File Storage')),
                                    actions.setModalAllowBack(false),
                                    actions.setAddComponentModalIsOpen(true),
                                ]}
                            >
                                <PlusIcon
                                    height={15}
                                    width={15}
                                />
                            </Button>
                        </Row>
                
                        <Droppable droppableId='fileStorageComponents'>
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef} className='w-100'>
                                    {values?.fileStorageComponents?.map((c, index) => (
                                        <DraggableComponent
                                            key={`${c._id ?? c.name}-${index}`}
                                            values={values}
                                            index={index}
                                            name={c.name}
                                            componentTypeName={c.componentTypeName}
                                            code={c.code}
                                            setFieldValue={setFieldValue}
                                            component={c}
                                            selectedComponents={values.fileStorageComponents}
                                            supportNested={false}
                                            location={'fileStorageComponents'}
                                            description={c.description}
                                        />                                                                                                  
                                    ))}
                                
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>

                    <CustomFieldSection
                        values={values}
                        setFieldValue={setFieldValue}
                        onAdd={() => [
                            actions.setCustomFieldModalIsOpen(true),
                        ]}
                    />
                </>
            )}

            <MarginSection
                disabledMarginProfile={packageDetails?.moduleRestrictions?.marginProfiles}
                values={values}
                marginsData={marginsData}
                role={role}
                setFieldValue={setFieldValue}
                marginProfilesData={marginProfilesData}
                onAddMargin={() => {
                    if (!packageDetails?.moduleRestrictions?.marginProfiles) {
                        actions.setModalAllowBack(true);
                        actions.setMarginProfileModalIsOpen(true);
                    } else {
                        actions.setModalAllowBack(true);
                        actions.setSelectedPricingRule('Margin');
                        actions.setPricingRuleModalIsOpen(true);
                    }
                }}
            />

            <DynamicPriceSection
                values={values}
                dynamicPriceData={dynamicPriceData}
                role={role}
                setFieldValue={setFieldValue}
                onAddDyamicPrice={() => [
                    actions.setModalAllowBack(true),
                    actions.setSelectedPricingRule('Dynamic Price'),
                    actions.setPricingRuleModalIsOpen(true),
                ]}
            />
            <ComponentSelector
                userId={userId}
                values={values}
                applicableSource={(selectedComponentType === 'Material' || selectedComponentType === 'Printing Cost')? 'loose-sheet-v2' : 'packaging'}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <PricingRuleSelector
                userId={userId}
                values={values}
                applicableSource={'packaging'}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />

            <MarginProfileSelector
                userId={userId}
                values={values}
                applicableSource={values.source}
                setFieldValue={setFieldValue}
                notificationOpen={notificationOpen}
            />
        </>
    );
};

const mapStateToProps = state => ({
    packageDetails: state.packageDetails.data,
    marginProfilesData: state.marginProfiles.data,
    componentsTypeData: state.componentsType.data,
    role: state.role.details.user?state.role.details.user.role:'',
});
  
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackagingComponentStructure));
